<template>
  <div class="default-item cursor-pointer" @mousedown="updateMousePosition($event,'down')" @mouseup="updateMousePosition($event,'up')">

    <div v-if="cardType === 'default'">
      <div v-if="target === 'mypage'" class="item-edit-box">
        <div class="item-state">
          <div v-if="item.saveStatus == 0" class="btn btn-x-sm btn-outline w-70 color-1">심사중</div>
          <div v-if="item.saveStatus == 1" class="btn btn-x-sm btn-outline w-70 color-4">심사완료</div>
          <div v-if="item.saveStatus == 9" class="btn btn-x-sm btn-outline w-70 color-6">임시저장</div>
        </div>
        <div v-if="item.saveStatus == 1 || item.saveStatus == 9" class="edite-icon-btn">
          <div class="w-25 px-5" @mousedown="updateMousePosition($event,'edit')">
            <div class="icon-img icon-write"></div>
          </div>
          <div class="w-25 px-5" @mousedown="updateMousePosition($event,'remove')">
            <div class="icon-img icon-trash"></div>
          </div>
        </div>
      </div>

      <div class="item-img">
        <icon-label :item="item"/>
        <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
      </div>
      <div class="item-con">
<!--        <div class="item-title">{{ item.title }}</div>-->
        <div class="ellipsis-title">{{ item.title }}</div>
        <div class="item-info">
          <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span>
          <span class="reply">{{ item.commentCount }}개의 댓글</span>
        </div>
        <div class="item-info2">
          <profile :profileType="'list'" :item="item"/>
          <div class="d-flex">
            <div class="like"><span class="icon-img icon-thumb"></span>  {{ item.likeCnt }}</div>
            <div class="hit"><span class="icon-img icon-eye"></span> {{ item.view ? Number(item.view).numberFormat() : 0 }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="cardType === 'youtube'">
      <div class="item-img">
        <div class="thumb-img youtube-ratio"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
      </div>
      <div class="item-con">
        <div class="item-title">{{ item.title }}</div>
        <div class="item-info">
          <span v-if="target === 'uploadDate'" class="date">{{ $getDateFormat(item.uploadDate, 'Y.m.d') }}</span>
          <span v-else class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span>
        </div>
        <div class="item-info2">
          <div class="profile-set">
            <div class="profile-info">
              <div><span class="nickname">{{ item.chanName }}</span></div>
            </div>
          </div>
          <div class="d-flex">
            <div class="hit"><span class="icon-img icon-eye"></span> {{ item.view ? Number(item.view).numberFormat() : 0 }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="cardType === 'simple'">

      <div class="item-img">
        <icon-label :item="item"/>
        <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
      </div>
      <div class="item-con">
        <div class="item-title">{{ item.title }}</div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "card",
  props: {
    cardType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    target: {
      type: String,
    },
  },
  data() {
    return {
      mousedownPositionX: 0,
      mousedownPositionY: 0,
      mouseupPositionX: 0,
      mouseupPositionY: 0,
    }
  },
  methods: {
    updateMousePosition(event, type) {
      if (type == 'edit') {
        this.$emit('edit')
        return false
      }
      if (type == 'remove') {
        this.$emit('remove')
        return false
      }
      // 익스플로러의 버전 체크하기
      let version_IE = "0";
      let ieLower = navigator.userAgent.match( /MSIE [0-9]{1,}/ );
      if ( ieLower != null ){  version_IE = ieLower.toString().replace( "MSIE " , "" );  }

      let x = 0; // 마우스 포인터의 좌측 위치
      let y = 0; // 마우스 포인터의 위쪽 위치

      if ( 0 < version_IE && version_IE < 7 ) { // 인터넷 익스플로러 (ie) 6 이하 버전일 경우 적용될 내용
        x = event.offsetX;
        y = event.offsetY;
      }
      else if ( event.pageX ) { // pageX & pageY를 사용할 수 있는 브라우저일 경우
        x = event.pageX;
        y = event.pageY;
      }
      else { // 그외 브라우저용
        x = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        y = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      }

      if (type == 'down') {
        this.mousedownPositionX = x;
        this.mousedownPositionY = y;
      }
      else {
        this.mouseupPositionX = x;
        this.mouseupPositionY = y;

        this.clickEvent();
      }
    },
    clickEvent() {
      if (this.mousedownPositionX == this.mouseupPositionX && this.mousedownPositionY == this.mouseupPositionY) {
        this.$emit("click")
      }
    },
  }
}
</script>
